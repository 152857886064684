import React, {useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DialogContent from '@mui/material/DialogContent';
import {
  TextField,
  Typography,
  Box,
  styled,
  InputLabel,
  Collapse,
  Grid,
  Link,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from '@mui/material';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {useStyles} from '@app/screens/Styles/style';
import {
  approveExpenseStateActions,
  fetchInvoiceDetailCreator,
  fetchMiscExpenseApprovalsDataCreator,
  saveSupportingDocCreator,
  submitBillStatusCreator,
} from '@app/screens/module-specific-utilities/pages/approve-expenses/redux/slice';
import {useDispatch, useSelector} from 'react-redux';
import {approveExpenseStateSelector} from '@app/screens/module-specific-utilities/pages/approve-expenses/redux/selectors';
import {ToasterService} from '@app/services';
import {ToastType} from '@app/services/toaster';
import {styles, billBoxStyle} from './billsStyles';
import {previewInNewWindow} from '../sub-components/ViewBillPopup';
import theme from '@app/themes';
import {strings} from '@app/common/strings';

export const utils: any = {
  isValidateBtnDisabled: null,
  isSubmitBtnDisabled: null,
  onValidateBtn: null,
  clearingData: null,
  changeHandler: null,
  handlePreview: null,
  getBillDetails: null,
  dateChange: null,
  saveBill: null,
  selectBill: null,
  supportingDocUpload: null,
  addNewBillClick: null,
  closingDialog: null,
  onSubmitBtn: null,
  resetFormFields: null,
};

const Input = styled('input')({
  display: 'none',
});

interface BillDetailsObj {
  invoiceNumber: string;
  invoiceDate: any;
  vendorName: string;
  invoiceTotalAmt: string;
  isBillVisible: boolean;
}

export const errorMsg = (
  divName: string,
  sapCode: string,
  staffName: string,
) => {
  let msg =
    'Invoice number already exists.( Division - ' +
    {divName} +
    ', SAP Code - ' +
    {sapCode} +
    ' and Staff Name -' +
    {staffName} +
    ' )';
  return msg;
};
const UploadBillPopup: React.FC<{
  open: boolean;
  onClose: () => void;
  changeHandlers: (event: any) => void;
  uploadDisabled: boolean;
  expenseId: string;
}> = ({open, onClose, changeHandlers, uploadDisabled, expenseId}) => {
  const billStyle = styles();
  const dispatch = useDispatch();
  const [billDetails, setBillDetails] = useState<BillDetailsObj>({
    invoiceNumber: '',
    invoiceDate: null,
    vendorName: '',
    invoiceTotalAmt: '',
    isBillVisible: true,
  });
  const [selectedBill, setSelectedBill] = useState<any>('');
  const [selectFileData, setSelectFileData] = useState<any>(null);
  const [selectFileBtnDisabled, setSelectFileBtnDisabled] = useState(true);
  const [addNewExpanded, setAddNewExpanded] = useState(false);
  const [fileTypeError, setFileTypeError] = useState(false);
  const [isDelKeyOrBckspc, setIsDelKeyOrBckspc] = useState<string>('');

  const isInvoiceExist = useSelector(
    approveExpenseStateSelector.getInvoiceStatusofBill(),
  );
  let divName = JSON.stringify(isInvoiceExist?.divName);
  let sapCode = isInvoiceExist?.sapCode;
  let staffName = isInvoiceExist?.staffName;
  const isBillSubmitted = useSelector(
    approveExpenseStateSelector.getSubmittedBillStatus(),
  );
  const isSupportingDocSubmitted = useSelector(
    approveExpenseStateSelector.getIsSupportingDocSubmitted(),
  );

  const miscExpData = useSelector(approveExpenseStateSelector.getMiscExpense());

  const isValidateBtnDisabled = () => {
    const {invoiceNumber, invoiceDate, vendorName} = billDetails;
    if (invoiceNumber?.length > 0 && !!invoiceDate && vendorName?.length > 0) {
      return false;
    }
    return true;
  };

  const isSubmitBtnDisabled = () => {
    const {invoiceNumber, invoiceDate, vendorName, invoiceTotalAmt} =
      billDetails;
    if (
      invoiceNumber?.length > 0 &&
      !!invoiceDate &&
      vendorName?.length > 0 &&
      invoiceTotalAmt?.length > 0 &&
      selectedBill
    ) {
      return false;
    }
    return true;
  };

  const onValidateBtn = () => {
    const {invoiceNumber, vendorName} = billDetails;
    dispatch(fetchInvoiceDetailCreator({invoiceNumber, vendorName}));
  };

  const clearingData = () => {
    setBillDetails({
      invoiceNumber: '',
      invoiceDate: null,
      vendorName: '',
      invoiceTotalAmt: '',
      isBillVisible: true,
    });
    setSelectFileData(null);
    setSelectFileBtnDisabled(true);
    setSelectedBill('');
    dispatch(approveExpenseStateActions.setInvoiceStatus(null));
  };

  React.useEffect(() => {
    if (isBillSubmitted?.isFileUploaded) {
      const requestBody = {
        month: miscExpData?.expenseMonth,
        staffPositionId: miscExpData?.staffPositionId,
        userId: miscExpData?.userId,
        year: miscExpData?.expenseYear,
      };
      dispatch(fetchMiscExpenseApprovalsDataCreator(requestBody));
    }
  }, [isBillSubmitted?.isFileUploaded]);

  const onSubmitBtn = () => {
    dispatch(approveExpenseStateActions.setSubmittedBillStatus({}));
    saveBill();
  };

  const closingDialog = () => {
    setAddNewExpanded(false);
    setFileTypeError(false);
    onClose();
    clearingData();
    dispatch(approveExpenseStateActions.setSubmittedBillStatus({}));
    dispatch(approveExpenseStateActions.setIsSupportingDocSubmitted(false));
  };

  const getBillDetails = (e: any) => {
    const {name, value} = e?.target ?? {};
    const {invoiceNumber, backspace, invoiceTotalAmount} =
      strings.moduleSpecificUtilities;
    if (
      (name === invoiceNumber &&
        billDetails?.invoiceNumber?.length >= 40 &&
        isDelKeyOrBckspc !== backspace) ||
      (name === invoiceTotalAmount &&
        billDetails?.invoiceTotalAmt?.length >= 5 &&
        isDelKeyOrBckspc !== backspace)
    ) {
      return;
    }
    let val = name == invoiceTotalAmount ? value?.replace(/\D/g, '') : value;
    setBillDetails(prev => ({...prev, [name]: val}));
  };

  const dateChange = (e: any) => {
    const date = new Date(e);
    setBillDetails({...billDetails, invoiceDate: date});
  };

  const visibilityChange = (e: any) => {
    setBillDetails({...billDetails, isBillVisible: e?.target?.checked});
  };

  const saveBill = () => {
    dispatch(approveExpenseStateActions.setInvoiceStatus(null));
    const {
      invoiceNumber,
      invoiceDate,
      vendorName,
      invoiceTotalAmt,
      isBillVisible,
    } = billDetails;
    const formattedDate = new Date(invoiceDate);
    const formData = new FormData();

    formData.append('IsBillVisible', isBillVisible ? 'true' : 'false');
    formData.append('ExpenseCategory', 'MISC');
    formData.append('IsAdminUploaded', 'true');

    formData.append('TADAMiscId', miscExpData?.expMiscHistoyId);
    formData.append(
      'Date',
      `${formattedDate.getFullYear()}-${
        formattedDate.getMonth() + 1
      }-${formattedDate.getDate()}`,
    );
    formData.append('InvoiceNumber', invoiceNumber);
    formData.append('Vendor', vendorName);
    formData.append('BillAmount', invoiceTotalAmt);
    formData.append('FileName', selectedBill?.name);
    formData.append('IsAdminModified', 'true');
    formData.append('IsBase64StringContent', 'false');
    formData.append('FilesBase64String', 'null');
    formData.append('File', selectedBill);

    if (selectedBill !== undefined) {
      let request = {
        id: expenseId,
        page: 'misc',
      };
      dispatch(submitBillStatusCreator({formData, request}));
    }
  };

  const selectBill = (e: any) => {
    const acceptFileFormat = ['.jpg', '.pdf', '.png'];
    const file = e?.target?.files[0];
    if (
      acceptFileFormat.some((extension: string) =>
        file.name.toLowerCase().endsWith(extension.toLowerCase()),
      )
    ) {
      setFileTypeError(false);
      if (file?.size < 3145728) {
        setSelectedBill(file);
      } else {
        ToasterService.showToaster(
          strings.moduleSpecificUtilities.greaterThan3MB,
          ToastType.ERROR,
        );
      }
    } else {
      setFileTypeError(true);
    }
  };

  const supportingDocUpload = (e: any) => {
    const files = e?.target?.files;

    if (files !== undefined) {
      let formData = new FormData();
      let upload = false;
      formData.append('BillId', isBillSubmitted?.billId);
      for (let row of files) {
        if (row?.size < 3145728) {
          upload = true;
          formData?.append('Files', row);
          formData?.append('FileName', row?.name);
          formData?.append('FilesBase64String', 'test');
          formData.append('IsBase64StringContent', 'false');
        } else {
          ToasterService.showToaster(
            strings.moduleSpecificUtilities.greaterThan3MB,
            ToastType.ERROR,
          );
        }
      }
      if (upload) {
        dispatch(saveSupportingDocCreator(formData));
      }
    }
  };

  const addNewBillClick = () => {
    setAddNewExpanded(prevState => !prevState);
    clearingData();
    dispatch(approveExpenseStateActions.setSubmittedBillStatus({}));
  };

  const blobToBase64 = (blob: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader?.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64String = reader?.result;
        resolve(base64String);
      };
      reader.onerror = () => {
        reject(new Error('Something went wrong'));
      };
    });
  };

  const handlePreview = (file: any) => {
    blobToBase64(file).then((res: any) =>
      previewInNewWindow(file?.type, res, file?.name, true),
    );
  };

  const resetFormFields = () => {
    dispatch(approveExpenseStateActions.setSubmittedBillStatus({}));
    dispatch(approveExpenseStateActions.setIsSupportingDocSubmitted(false));
    setAddNewExpanded(false);
    setFileTypeError(false);
  };
  utils.isValidateBtnDisabled = isValidateBtnDisabled;
  utils.isSubmitBtnDisabled = isSubmitBtnDisabled;
  utils.onValidateBtn = onValidateBtn;
  utils.clearingData = clearingData;
  utils.handlePreview = handlePreview;
  utils.getBillDetails = getBillDetails;
  utils.dateChange = dateChange;
  utils.saveBill = saveBill;
  utils.selectBill = selectBill;
  utils.supportingDocUpload = supportingDocUpload;
  utils.addNewBillClick = addNewBillClick;
  utils.closingDialog = closingDialog;
  utils.onSubmitBtn = onSubmitBtn;
  utils.resetFormFields = resetFormFields;

  return (
    <Dialog sx={{width: '100%'}} open={open} onClose={closingDialog}>
      <DialogTitle sx={billBoxStyle.dialogTitle}>
        Please upload jpg, png & pdf file format only. Maximum upload file size
        is 3 MB.
        <Button onClick={closingDialog}>
          <CloseOutlinedIcon />
        </Button>
      </DialogTitle>
      <DialogContent className={billStyle.DialogContent}>
        <div style={billBoxStyle.dialogContentDiv}>
          {(isBillSubmitted?.isFileUploaded || isSupportingDocSubmitted) && (
            <Typography className={billStyle.uploadSuccess}>
              {`The ${
                isSupportingDocSubmitted ? 'supporting document' : 'Bill'
              } is Successfully Submitted`}
            </Typography>
          )}
          {isBillSubmitted?.error && (
            <Typography color="error" className={billStyle.billErrorMsg}>
              {strings.moduleSpecificUtilities.billNotSubmitted}
            </Typography>
          )}
          <Button
            variant={addNewExpanded ? 'text' : 'contained'}
            className={billStyle.addNewBillBtn}
            onClick={addNewBillClick}>
            Add New Bill
          </Button>
          <Collapse in={addNewExpanded} timeout="auto" unmountOnExit>
            <Box border={1} p={3.4} mb={2} borderRadius={2}>
              <Grid className={billStyle.gridContainer} container spacing={2}>
                <FileEditableField
                  columnName={strings.moduleSpecificUtilities.INVOICENUMBER}
                  value={billDetails.invoiceNumber}
                  name={strings.moduleSpecificUtilities.invoiceNumber}
                  editable={isInvoiceExist?.isValid}
                  changeValue={getBillDetails}
                  type="text"
                  isDisable={
                    isBillSubmitted?.isFileUploaded || isSupportingDocSubmitted
                  }
                  keyChecker={setIsDelKeyOrBckspc}
                />
                <Box sx={{marginTop: 1}}>
                  <FileEditableField
                    columnName={strings.moduleSpecificUtilities.INVOICEDATE}
                    value={billDetails.invoiceDate}
                    name={strings.moduleSpecificUtilities.invoiceDate}
                    editable={false}
                    changeValue={dateChange}
                    type="date"
                    isDisable={
                      isBillSubmitted?.isFileUploaded ||
                      isSupportingDocSubmitted
                    }
                    keyChecker={setIsDelKeyOrBckspc}
                  />
                </Box>
                <FileEditableField
                  columnName={strings.moduleSpecificUtilities.VENDORNAME}
                  value={billDetails.vendorName}
                  name={strings.moduleSpecificUtilities.vendorName}
                  editable={isInvoiceExist?.isValid}
                  changeValue={getBillDetails}
                  type="text"
                  isDisable={
                    isBillSubmitted?.isFileUploaded || isSupportingDocSubmitted
                  }
                  keyChecker={setIsDelKeyOrBckspc}
                />
                <Button
                  data-testid={'validate'}
                  disabled={
                    isValidateBtnDisabled() ||
                    isBillSubmitted?.isFileUploaded ||
                    isSupportingDocSubmitted
                  }
                  onClick={() => onValidateBtn()}
                  variant="contained"
                  color="primary"
                  className={billStyle.validateBtn}
                  size="small">
                  {strings.moduleSpecificUtilities.vaildate}
                </Button>
                <FileEditableField
                  editable={!selectFileBtnDisabled}
                  columnName={strings.moduleSpecificUtilities.INVOICEAMOUNT}
                  value={billDetails.invoiceTotalAmt}
                  name={strings.moduleSpecificUtilities.invoiceTotalAmount}
                  changeValue={getBillDetails}
                  type="text"
                  isDisable={
                    isBillSubmitted?.isFileUploaded || isSupportingDocSubmitted
                  }
                  keyChecker={setIsDelKeyOrBckspc}
                />
              </Grid>
              <Box className={billStyle.uploadBillBox}>
                <Input
                  aria-disabled
                  accept=".pdf,.png,.jpg"
                  id="contained-button-file2"
                  type="file"
                  onChange={selectBill}
                  disabled={
                    !isInvoiceExist?.isValid ||
                    isBillSubmitted?.isFileUploaded ||
                    isSupportingDocSubmitted
                  }
                />
                <Button
                  disabled={
                    !isInvoiceExist?.isValid ||
                    isBillSubmitted?.isFileUploaded ||
                    isSupportingDocSubmitted
                  }
                  component="span"
                  variant="contained"
                  color="primary"
                  className={billStyle.uploadBillBtn}>
                  <InputLabel
                    htmlFor="contained-button-file2"
                    disabled={
                      !isInvoiceExist?.isValid ||
                      isBillSubmitted?.isFileUploaded ||
                      isSupportingDocSubmitted
                    }
                    style={{
                      fontSize: 9,
                      minWidth: 61,
                      color: !isInvoiceExist?.isValid
                        ? theme.colors.grey70
                        : theme.colors.white,
                      fontWeight: '600',
                    }}>
                    {strings.moduleSpecificUtilities.uploadBill}
                  </InputLabel>
                </Button>
                {fileTypeError && (
                  <span style={{color: 'red'}}>
                    Selected file format is not valid. Please select jpg, png or
                    pdf file format.
                  </span>
                )}
                <Link
                  onClick={() => handlePreview(selectedBill)}
                  className={billStyle.previewLink}>
                  {selectedBill?.name}
                </Link>
                {selectedBill?.name && (
                  <FormGroup>
                    <FormControlLabel
                      sx={{'& .MuiTypography-root': {fontSize: 12}}}
                      control={
                        <Checkbox
                          checked={billDetails?.isBillVisible}
                          size="small"
                          onChange={visibilityChange}
                        />
                      }
                      label={strings.label.billVisible}
                    />
                  </FormGroup>
                )}
              </Box>
              {selectFileData !== null && (
                <Typography>{selectFileData?.filename}</Typography>
              )}
              {isInvoiceExist?.isValid === false && (
                <Typography color="error" className={billStyle.errorMsg}>
                  Invoice number already exists.({isInvoiceExist?.month}-
                  {isInvoiceExist?.year}, Division - {divName}, SAP Code -{' '}
                  {sapCode} and Staff Name -{staffName} )
                </Typography>
              )}

              {isInvoiceExist?.isValid === true && (
                <Typography className={billStyle.validationSuccessMsg}>
                  {strings.moduleSpecificUtilities.validationSuccess}
                </Typography>
              )}

              <Button
                disabled={
                  isSubmitBtnDisabled() ||
                  isBillSubmitted?.isFileUploaded ||
                  isSupportingDocSubmitted
                }
                onClick={() => onSubmitBtn()}
                variant="contained"
                className={billStyle.saveBtn}
                color="primary">
                {strings.save}
              </Button>
              <Input
                aria-disabled
                accept=".pdf,.png,.jpg"
                id="contained-button-file3"
                type="file"
                multiple
                onChange={supportingDocUpload}
              />
              <Button
                disabled={
                  !isBillSubmitted?.isFileUploaded || isSupportingDocSubmitted
                }
                component="span"
                variant="contained"
                color="primary"
                sx={billBoxStyle.suppDocBtn}>
                <InputLabel
                  htmlFor="contained-button-file3"
                  disabled={
                    !isBillSubmitted?.isFileUploaded || isSupportingDocSubmitted
                  }
                  style={{
                    fontSize: 10,
                    minWidth: 51,
                    color:
                      !isBillSubmitted?.isFileUploaded ||
                      isSupportingDocSubmitted
                        ? theme.colors.grey70
                        : theme.colors.white,
                    fontWeight: '600',
                  }}>
                  {strings.moduleSpecificUtilities.supportingDoc}
                </InputLabel>
              </Button>
              <Button
                onClick={resetFormFields}
                variant="contained"
                sx={billBoxStyle.cancelBtn}
                color="primary">
                {strings.cancel}
              </Button>
            </Box>
          </Collapse>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export const FileEditableField: React.FC<{
  columnName: string;
  editable?: boolean;
  value: any;
  name?: string;
  changeValue: (text?: any) => void;
  type: string;
  isDisable?: boolean;
  keyChecker: (e: any) => void;
}> = ({
  columnName,
  editable = false,
  value,
  name,
  changeValue,
  type,
  isDisable,
  keyChecker,
}) => {
  const classes = useStyles();
  const style = styles();
  return (
    <div style={{marginRight: 10}}>
      <Typography className={style.editableText}>{columnName}</Typography>
      {type !== 'date' ? (
        <TextField
          disabled={isDisable}
          variant="outlined"
          style={billBoxStyle.textField}
          value={value}
          name={name}
          type={type}
          size="small"
          placeholder={strings.placeholder.enterValue}
          onKeyDown={e => keyChecker(e?.key)}
          onChange={event => changeValue(event)}
          inputProps={{
            style: billBoxStyle.textFieldInputProps,
          }}
        />
      ) : (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            disabled={isDisable}
            InputProps={{
              style: billBoxStyle.datePickerInputProps,
            }}
            className={classes.datepick}
            value={value}
            inputFormat="dd-MM-yyyy"
            onChange={newValue => {
              changeValue(newValue);
            }}
            renderInput={params => <TextField value={value} {...params} />}
          />
        </LocalizationProvider>
      )}
    </div>
  );
};

export default UploadBillPopup;
