import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit';

interface NavState {
  title: string | null;
}

interface InitialStateProps {
  nav: NavState;
  loading: boolean;
  allReporters: {
    id: number;
    firstName: string;
    lastName: string;
    staffPositionId: number;
  }[];
  balanceLeave: {};
  leaveHistory: [];
}
const initialState: InitialStateProps = {
  nav: {
    title: null,
  },
  loading: false,
  allReporters: [],
  balanceLeave: {},
  leaveHistory: [],
};

export const updateLeaveHolidayNavDataCreator = createAction<any>(
  'LEAVE_MANAGAMENT/UPDATE_LEAVE_NAV_DATA',
);
export const updateLeaveHolidayNavDataCreatorTypeName =
  updateLeaveHolidayNavDataCreator(null).type;

export const fetchReportersCreator = createAction<any>(
  'LEAVE_MANAGAMENT/GET_ALL_REPORTERS',
);
export const fetchReportersApiTypeName = fetchReportersCreator(null).type;

export const allBalanceLeaveCreator = createAction<any>(
  'LEAVE_MANAGAMENT/ALL_BALANCE_LEAVE',
);
export const allBalanceLeaveApiTypeName = allBalanceLeaveCreator(null).type;

export const fetchLeaveHistoryListCreator = createAction<any>(
  'LEAVE_MANAGAMENT/FETCH_LEAVE_HISTORY_LIST',
);
export const fetchLeaveHistoryApiTypeName =
  fetchLeaveHistoryListCreator(null).type;

const leaveSlice = createSlice({
  name: 'LEAVE_CONFIGURATION',
  initialState: initialState,
  reducers: {
    updateNavData: (state, action: PayloadAction<Partial<NavState>>) => {
      state.nav = {...state.nav, ...action.payload};
    },
    setLoading: (state, action) => {
      return {
        ...state,
        loading: action.payload,
      };
    },
    setReporters: (state, action) => {
      return {
        ...state,
        allReporters: action.payload,
      };
    },
    setBalanceLeave: (state, action) => {
      return {
        ...state,
        balanceLeave: action.payload,
      };
    },
    setLeaveHistory: (state, action) => {
      return {
        ...state,
        leaveHistory: action.payload,
      };
    },
  },
});

export const {
  updateNavData,
  setLoading,
  setReporters,
  setBalanceLeave,
  setLeaveHistory,
} = leaveSlice.actions;

export const leaveManagmentReducer = leaveSlice.reducer;
