import {createSelector} from '@reduxjs/toolkit';

export const statusManagerStateSelector = {
  getStatusManagerAutoSuggestStaffDetail: () => {
    return createSelector(
      [
        (state: any) =>
          state.statusManager.statusManagerAutoSuggestStaffDetails,
      ],
      statusManagerAutoSuggestStaffDetails =>
        statusManagerAutoSuggestStaffDetails,
    );
  },
  getStatusManagerAutoSuggestLoading: () => {
    return createSelector(
      [(state: any) => state.statusManager.statusManagerAutoSuggestLoading],
      statusManagerAutoSuggestLoading => statusManagerAutoSuggestLoading,
    );
  },
  getStatusManagerAction: () => {
    return createSelector(
      [(state: any) => state.statusManager.statusManagerAction],
      statusManagerAction => statusManagerAction,
    );
  },
  getActiveStatus: () => {
    return createSelector(
      [(state: any) => state.statusManager.activeStatus],
      activeStatus => activeStatus,
    );
  },
  getToggleStatus: () => {
    return createSelector(
      [(state: any) => state.statusManager.toggleStatus],
      toggleStatus => toggleStatus,
    );
  },
};
