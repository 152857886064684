import {call, put, takeLatest} from 'redux-saga/effects';
import {
  allBalanceLeaveApiTypeName,
  fetchLeaveHistoryApiTypeName,
  fetchReportersApiTypeName,
  setBalanceLeave,
  setLeaveHistory,
  setLoading,
  setReporters,
} from './slice';

import {API_PATH} from '@app/common/constants';
import NetworkService from '@app/services/network/NetworkService';

export function* fetchReportersApiWatcher() {
  yield takeLatest(fetchReportersApiTypeName, fetchReportersHandler);
}

export function* fetchReportersHandler(actions: any): any {
  const {staffPositionId} = actions.payload;
  try {
    yield put(setLoading(true));
    const url = API_PATH.holidayManagement.getSubordinates + staffPositionId;
    const response = yield call(NetworkService.get, url, {}, {});
    yield put(setReporters(response.data || []));
    yield put(setLoading(false));
  } catch (error) {
    yield put(setLoading(false));
    console.error('Error fetching fetchReportersHandler:', error);
  }
}
export function* fetchGetAllBalanceLeaveApiWatcher() {
  yield takeLatest(allBalanceLeaveApiTypeName, fetchAllBalanceLeaveHandler);
}

export function* fetchAllBalanceLeaveHandler(actions: any): any {
  const {c_year, ssStaffPositionId} = actions.payload;
  try {
    yield put(setLoading(true));
    const url =
      API_PATH.holidayManagement.getBalanceLeaveByUserId +
      ssStaffPositionId +
      '&year=' +
      c_year;
    const response = yield call(NetworkService.post, url, {}, {});
    yield put(setBalanceLeave(response?.data || {}));
    yield put(setLoading(false));
  } catch (error) {
    yield put(setLoading(false));
    console.error('Error fetching fetchReportersHandler:', error);
  }
}

export function* fetchLeaveHistoryWatcher() {
  yield takeLatest(fetchLeaveHistoryApiTypeName, fetchLeaveHistoryListHandler);
}

export function* fetchLeaveHistoryListHandler(actions: any): any {
  const {c_year, ssStaffPositionId} = actions.payload;
  try {
    yield put(setLoading(true));
    const url =
      API_PATH.holidayManagement.getListRequestLeavesByUserId +
      ssStaffPositionId +
      '&year=' +
      c_year;
    const response = yield call(NetworkService.post, url, {}, {});
    yield put(setLeaveHistory(response.data || []));
    yield put(setLoading(false));
  } catch (error) {
    yield put(setLoading(false));
    console.error('Error fetching fetchReportersHandler:', error);
  }
}
