import {
  getFlatRows,
  updatedDailyData,
  updatedMiscData,
} from '@app/screens/module-specific-utilities/components/approve-expenses/utils';
import {createAction, createSlice} from '@reduxjs/toolkit';

export const approveExpenseInitialState = {
  approveExpesnseFilterValues: {},

  divisionLoading: false,
  divisionData: [],
  divisionError: false,
  divisionMsg: '',

  stateLoading: false,
  stateData: [],
  stateError: false,
  stateMsg: '',

  managerLoading: false,
  managerData: [],
  managerError: false,
  managerMsg: '',
  selectedManager: null,

  staffLoading: false,
  staffData: [],
  staffError: false,
  staffMsg: '',
  selectedStaff: [],
  monthsData: [],

  yearsData: [],

  statusLoading: false,
  statusData: [],
  statusError: false,
  statusMsg: '',

  monthYearLoading: false,
  monthYearData: [],
  monthYearError: false,
  monthYearMsg: '',

  miscExpense: [],

  expenseApprovalsLoading: false,
  expenseApprovalsData: [],
  expenseApprovalsError: false,
  expenseApprovalsMsg: '',
  selectedExpenseApprovalsData: [],
  flattenedExpenseApprovalsData: [],

  isExpenseAprrovalsAllSelected: false,

  approveExpenseScreen: {
    screen: 1,
    backBtn: false,
  },

  dailyExpenseExceptionSummaryData: [],

  selectedExpenseApprovalsUser: {},

  dailyExpenseApprovalsLoading: false,
  dailyExpenseApprovalsData: [],
  dailyExpenseApprovalsError: false,
  dailyExpenseApprovalsMsg: '',
  dailys: {},
  miscExpenseApprovalsRequest: [],
  miscExpenseApprovalsLoading: false,
  miscExpenseApprovalsData: [],
  miscExpenseApprovalsError: false,
  miscExpenseApprovalsMsg: '',
  miscs: {},

  isAprroveExpenseModalOpen: false,
  approveExpenseModalCheckbox: false,

  confirmExpenseApprovalsLoading: false,
  confirmExpenseApprovalsSucess: false,
  confirmExpenseApprovalsError: false,
  confirmExpenseApprovalsMsg: '',

  isRejectExpenseModalOpen: false,
  rejectExpenseModalCheckbox: false,

  rejectExpenseApprovalsLoading: false,
  rejectExpenseApprovalsSucess: false,
  rejectExpenseApprovalsError: false,
  rejectExpenseApprovalsMsg: '',

  setDcrReportPopupVisible: false,
  setViewBillPopupVisible: false,
  setErrorPopupVisible: false,

  setDeductTheAmountPopup: false,

  daAmountToDeduct: 0,

  expenseApprovalsViewBillLoading: false,
  expenseApprovalsViewBillData: [],
  expenseApprovalsViewBillError: false,
  expenseApprovalsViewBillMsg: '',

  clickableLinkViewBillLoading: false,
  clickableLinkViewBillData: [],
  clickableLinkViewBillError: false,
  clickableLinkViewBillMsg: '',

  dailyExpenseDcrReportLoading: false,
  dailyExpenseDcrReportData: [],
  dailyExpenseDcrReportError: false,
  dailyExpenseDcrReportMsg: '',

  dailyExpenseDcrMapUrl: '',
  dailyExpenseHQTypeAdmin: '',
  dailyEditedRowColorIndex: [],
  dailyEditedRowColorIndexArr1: [],
  miscEditedRowColorIndex: [],
  taDaPopupVisible: false,
  dailyTaEditedRowColorIndex: [],
  dailyDaEditedRowColorIndex: [],
  popupReadOnlyData: [],
  popuptadaData: [],
  taGrid: [],
  daGrid: [],
  taRate: 0,
  billPathArr: [],
  pageNo: 0,
  disableSave: false,
  approveExpensestatus: {},
  disableDaSave: false,
  disableaApproveExpense: false,
  approvedStaffDetails: '',
  deductAmount: {},
  travelDetailPopup: false,
  travelDetails: [],
  monthlyExpense: [],
  footerDatas: {},
  downloadAllData: [],
  isInvoiceExist: null,
  IsBillSubmitted: {},
  billDetails: [],
  isBillDeleted: false,
  isSupportingDocSubmitted: false,
  dialyAllData: [],
  singleUserDailyExpense: [],
  tableMessage: false,
};

export const fetchStateDataCreator = createAction(
  'APPROVE_EXPENSE_HANDLER/FETCH_STATE_DATA',
);
export const fetchStateDataTypeName = fetchStateDataCreator().type;

export const fetchDivisionDataCreator = createAction(
  'APPROVE_EXPENSE_HANDLER/FETCH_DIVISION_DATA',
);
export const fetchDivisionDataTypeName = fetchDivisionDataCreator().type;

export const fetchStatusDataCreator = createAction(
  'APPROVE_EXPENSE_HANDLER/FETCH_STATUS_DATA',
);
export const fetchStatusDataTypeName = fetchStatusDataCreator().type;

export const fetchManagerDataCreator = createAction<any>(
  'APPROVE_EXPENSE_HANDLER/FETCH_MANAGER_DATA',
);
export const fetchManagerDataTypeName = fetchManagerDataCreator({}).type;

export const fetchStaffDataCreator = createAction<any>(
  'APPROVE_EXPENSE_HANDLER/FETCH_STAFF_DATA',
);
export const fetchStaffDataTypeName = fetchStaffDataCreator({}).type;

export const fetchExpenseApprovalsDataCreator = createAction<any>(
  'APPROVE_EXPENSE_HANDLER/FETCH_EXPENSE_APPROVALS_DATA',
);
export const fetchExpenseApprovalsDataTypeName =
  fetchExpenseApprovalsDataCreator({}).type;

export const fetchDailyExpenseApprovalsDataCreator = createAction<any>(
  'APPROVE_EXPENSE_HANDLER/FETCH_DAILY_EXPENSE_APPROVALS_DATA',
);
export const fetchDailyExpenseApprovalsDataTypeName =
  fetchDailyExpenseApprovalsDataCreator({}).type;

export const fetchMiscExpenseApprovalsDataCreator = createAction<any>(
  'APPROVE_EXPENSE_HANDLER/FETCH_MISC_EXPENSE_APPROVALS_DATA',
);
export const fetchMiscExpenseApprovalsDataTypeName =
  fetchMiscExpenseApprovalsDataCreator({}).type;

export const dailyExpenseExceptionSummary = createAction<any>(
  'APPROVE_EXPENSE_HANDLER/EXCEPTION_SUMMARY_DATA',
);
export const dailyExpenseExceptionSummaryType = dailyExpenseExceptionSummary(
  {},
).type;
export const fetchMiscExpenseFileDeleteDataCreator = createAction<any>(
  'APPROVE_EXPENSE_HANDLER/DELETE_MISC_EXPENSE_FILE',
);
export const fetchMiscExpenseFileDeleteDataTypeName =
  fetchMiscExpenseFileDeleteDataCreator({}).type;

export const fetchExpenseApproveStatusCreator = createAction<any>(
  'APPROVE_EXPENSE_HANDLER/FETCH_EXPENSE_APPROVE_STATUS',
);
export const fetchExpenseApproveStatusTypeName =
  fetchExpenseApproveStatusCreator({}).type;

export const fetchExpenseRejectStatusCreator = createAction<any>(
  'APPROVE_EXPENSE_HANDLER/FETCH_EXPENSE_REJECT_STATUS',
);
export const fetchExpenseRejectStatusTypeName = fetchExpenseRejectStatusCreator(
  {},
).type;

export const fetchDailyExpenseApprovalViewBillListCreator = createAction<any>(
  'APPROVE_EXPENSE_HANDLER/FETCH_DAILY_EXPENSE_APPROVALS_VIEW_BILL_DATA',
);
export const fetchDailyExpenseApprovalViewBillListTypeName =
  fetchDailyExpenseApprovalViewBillListCreator({}).type;

export const uploadMiscExpenseDataCreator = createAction<any>(
  'APPROVE_EXPENSE_HANDLER/UPLOAD_MISC_EXPENSE_FILE',
);
export const uploadMiscExpenseDataTypeName =
  uploadMiscExpenseDataCreator(null).type;

export const fetchMiscExpenseApprovalViewBillListCreator = createAction<any>(
  'APPROVE_EXPENSE_HANDLER/FETCH_MISC_EXPENSE_APPROVALS_VIEW_BILL_DATA',
);
export const fetchMiscExpenseApprovalViewBillListTypeName =
  fetchMiscExpenseApprovalViewBillListCreator({}).type;

export const fetchDailyExpenseDcrReportDataCreator = createAction<any>(
  'APPROVE_EXPENSE_HANDLER/FETCH_DAILY_EXPENSE_APPROVALS_DCR_REPORT',
);
export const fetchDailyExpenseDcrReportDataTypeName =
  fetchDailyExpenseDcrReportDataCreator({}).type;

export const fetchDailyExpenseDcrMapUrlDataCreator = createAction<any>(
  'APPROVE_EXPENSE_HANDLER/FETCH_DAILY_EXPENSE_DCR_MAP_URL',
);
export const fetchDailyExpenseDcrMapUrlDataTypeName =
  fetchDailyExpenseDcrMapUrlDataCreator({}).type;
export const fetchDailyExpensePopupReadOnlyData = createAction<any>(
  'APPROVE_EXPENSE_HANDLER/FETCH_DAILY_EXPENSE_POPUP_READONLY_DATA',
);
export const fetchDailyExpensePopupReadOnlyDataTypeName =
  fetchDailyExpensePopupReadOnlyData({}).type;

export const fetchDailyExpensePopupTADAData = createAction<any>(
  'APPROVE_EXPENSE_HANDLER/FETCH_DAILY_EXPENSE_POPUP_TA_DA_DATA',
);
export const fetchDailyExpensePopupTADADataTypeName =
  fetchDailyExpensePopupTADAData({}).type;

export const fetchDailyExpensePopupTADASaveData = createAction<any>(
  'APPROVE_EXPENSE_HANDLER/FETCH_DAILY_EXPENSE_POPUP_TA_DA_SAVE_DATA',
);
export const fetchDailyExpensePopupTADASaveDataTypeName =
  fetchDailyExpensePopupTADASaveData({}).type;

export const miscSaveCreator = createAction<any>(
  'APPROVE_EXPENSE_HANDLER/MISC_SAVE',
);
export const miscSaveCreatorTypeName = miscSaveCreator({}).type;

export const footerDataCreator = createAction<any>(
  'APPROVE_EXPENSE_HANDLER/FOOTER_DATA',
);
export const footerDataCreatorTypeName = footerDataCreator({}).type;

export const deductDaAmountCreator = createAction<any>(
  'APPROVE_EXPENSE_HANDLER/DEDUCT_DA_AMOUNT',
);
export const deductDaAmountCreatorTypeName = deductDaAmountCreator({}).type;

export const holidayCalenderCreator = createAction<any>(
  'APPROVE_EXPENSE_HANDLER/HOLIDAY_CALENDER',
);
export const holidayCalenderCreatorTypeName = holidayCalenderCreator({}).type;

export const approveExpenseStatusCreator = createAction<any>(
  'APPROVE_EXPENSE_HANDLER/APPROVE_EXPENSE_STATUS',
);
export const approveExpenseStatusCreatorTypeName = approveExpenseStatusCreator(
  {},
).type;
export const fetchMonthlyExpenseCreator = createAction<any>(
  'APPROVE_EXPENSE_HANDLER/FETCH_MONTHLY_EXPENSE',
);
export const fetchMonthlyExpenseTypeName = fetchMonthlyExpenseCreator({}).type;

export const fetchInvoiceDetailCreator = createAction<any>(
  'APPROVE_EXPENSE_HANDLER/FETCH_INVOICE_DETAIL',
);
export const fetchInvoiceDetailCreatorTypeName = fetchInvoiceDetailCreator(
  {},
).type;
export const submitBillStatusCreator = createAction<any>(
  'APPROVE_EXPENSE_HANDLER/SUBMIT_BILL_STATUS',
);
export const submitBillStatusCreatorTypeName = submitBillStatusCreator({}).type;
export const fetchBilDetailsCreator = createAction<any>(
  'APPROVE_EXPENSE_HANDLER/FETCH_BILL_DETAILS',
);
export const fetchBilDetailsCreatorTypeName = fetchBilDetailsCreator({}).type;
export const saveSupportingDocCreator = createAction<any>(
  'APPROVE_EXPENSE_HANDLER/SAVE_SUPPORTING_DOCS',
);
export const saveSupportingDocCreatorTypeName = saveSupportingDocCreator(
  {},
).type;
export const deleteBillCreator = createAction<any>(
  'APPROVE_EXPENSE_HANDLER/DELETE_BILL',
);
export const deleteBillCreatorTypeName = deleteBillCreator({}).type;

export const fetchDailyAllExpenseCreator = createAction<any>(
  'APPROVE_EXPENSE_HANDLER/FETCH_DAILY_ALL_EXPENSE',
);
export const fetchDailyAllExpenseTypeName = fetchDailyAllExpenseCreator(
  {},
).type;
export const fetchMonthlyAllExpenseCreator = createAction<any>(
  'APPROVE_EXPENSE_HANDLER/FETCH_MONTHLY_ALL_EXPENSE',
);
export const fetchMonthlyAllExpenseTypeName = fetchMonthlyAllExpenseCreator(
  {},
).type;

const approveExpenseStateHandler = createSlice({
  name: 'APPROVE_EXPENSE_HANDLER',
  initialState: approveExpenseInitialState,
  reducers: {
    // filter selected data
    setApproveExpesnseFilterValues: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        approveExpesnseFilterValues: {
          ...prevState.approveExpesnseFilterValues,
          ...payload,
        },
      };
    },
    resetApproveExpesnseFilterValues: prevState => {
      return {
        ...prevState,
        approveExpesnseFilterValues: {
          ['statusId']: ['Unapproved'],
        },
      };
    },
    // MonthYear data for DD
    setMonthYearLoading: prevState => {
      return {
        ...prevState,
        monthYearLoading: true,
        monthYearData: [],
        monthYearError: false,
        monthYearMsg: '',
      };
    },
    setMonthYearData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        monthYearLoading: false,
        monthYearData: payload,
        monthYearError: false,
        // monthYearMsg:'',
      };
    },
    setMonthYearError: prevState => {
      return {
        ...prevState,
        monthYearLoading: false,
        monthYearData: [],
        monthYearError: true,
        // monthYearMsg:'',
      };
    },

    // division data for DD
    setDivisionLoading: prevState => {
      return {
        ...prevState,
        divisionLoading: true,
        divisionData: [],
        divisionError: false,
        divisionMsg: '',
      };
    },
    setDivisionData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        divisionLoading: false,
        divisionData: payload,
        divisionError: false,
        // divisionMsg:'',
      };
    },
    setDivisionError: prevState => {
      return {
        ...prevState,
        divisionLoading: false,
        divisionData: [],
        divisionError: true,
        // divisionMsg:'',
      };
    },

    // state data for DD
    setStateLoading: prevState => {
      return {
        ...prevState,
        stateLoading: true,
        stateData: [],
        stateError: false,
        stateMsg: '',
      };
    },
    setStateData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        stateLoading: false,
        stateData: payload,
        stateError: false,
        // stateMsg:'',
      };
    },
    setStateError: prevState => {
      return {
        ...prevState,
        stateLoading: false,
        stateData: [],
        stateError: true,
        // stateMsg:'',
      };
    },

    // Manager data for DD
    setManagerLoading: prevState => {
      return {
        ...prevState,
        managerLoading: true,
        managerData: [],
        managerError: false,
        managerMsg: '',
      };
    },
    setManagerData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        managerLoading: false,
        managerData: payload,
        managerError: false,
        // managerMsg:'',
      };
    },
    setManagerError: prevState => {
      return {
        ...prevState,
        managerLoading: false,
        managerData: [],
        managerError: true,
        // managerMsg:'',
      };
    },
    setSelectedManagerData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        selectedManager: payload,
      };
    },

    // Staff data for DD
    setStaffLoading: prevState => {
      return {
        ...prevState,
        staffLoading: true,
        staffData: [],
        staffError: false,
        // staffMsg: '',
      };
    },
    setStaffData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        staffLoading: false,
        staffData: payload,
        staffError: false,
        // staffMsg:'',
      };
    },
    setStaffError: prevState => {
      return {
        ...prevState,
        staffLoading: false,
        staffData: [],
        staffError: true,
        // staffMsg:'',
      };
    },
    setSelectedStaffData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        selectedStaff: payload,
      };
    },

    // months data
    setMonthsValues: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        monthsData: payload,
      };
    },

    // years data
    setYearsValues: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        yearsData: payload,
      };
    },

    // status DD data
    setStatusLoading: prevState => {
      return {
        ...prevState,
        statusLoading: true,
        statusData: [],
        statusError: false,
        //   statusMsg: '',
      };
    },
    setStatusData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        statusLoading: false,
        statusData: payload,
        statusError: false,
        //   statusMsg: '',
      };
    },
    setStatusError: prevState => {
      return {
        ...prevState,
        statusLoading: false,
        statusData: [],
        statusError: true,
        //   statusMsg: '',
      };
    },

    // expenseApprovals
    setExpenseApprovalsLoading: prevState => {
      return {
        ...prevState,
        expenseApprovalsLoading: true,
        expenseApprovalsData: [],
        expenseApprovalsError: false,
        //   expenseApprovalsMsg:''
      };
    },
    setExpenseApprovalsData: (prevState, action) => {
      const {payload} = action;
      let data =
        payload && payload.length > 0 && payload[0] !== null ? payload : [];
      return {
        ...prevState,
        expenseApprovalsLoading: false,
        expenseApprovalsData: data,
        expenseApprovalsError: false,
        flattenedExpenseApprovalsData: getFlatRows(data),
        //   expenseApprovalsMsg:''
      };
    },
    setExpenseApprovalsError: prevState => {
      return {
        ...prevState,
        expenseApprovalsLoading: false,
        expenseApprovalsData: [],
        expenseApprovalsError: true,
        //   expenseApprovalsMsg:''
      };
    },
    setSelectedExpenseApprovalsData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        selectedExpenseApprovalsData: payload,
        isExpenseAprrovalsAllSelected:
          payload?.length === prevState?.flattenedExpenseApprovalsData?.length,
        selectedExpenseApprovalsUser: {},
      };
    },

    // all select checkbox
    setIsExpenseAprrovalsAllSelected: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        isExpenseAprrovalsAllSelected: payload,
      };
    },

    // screen and back btn
    setApproveExpenseScreen: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        approveExpenseScreen: payload,
      };
    },
    setMiscExpense: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        miscExpense: payload,
      };
    },

    //  selected user data
    setSelectedExpenseApprovalsUser: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        selectedExpenseApprovalsUser: payload,
      };
    },
    resetSelectedExpenseApprovalsUser: prevState => {
      return {
        ...prevState,
        selectedExpenseApprovalsUser: {},
      };
    },

    // Daily expense approvals data
    setDailyExpenseApprovalsLoading: prevState => {
      return {
        ...prevState,
        dailyExpenseApprovalsLoading: true,
        dailyExpenseApprovalsData: [],
        dailyExpenseApprovalsError: false,

        // dailyExpenseApprovalsMsg: '',
      };
    },
    setDailyExpenseApprovalsData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        dailyExpenseApprovalsLoading: false,
        dailyExpenseApprovalsData: payload,
        dailyExpenseApprovalsError: false,
        dailys: updatedDailyData(payload),
        dailyEditedRowColorIndex: [],
        dailyEditedRowColorIndexArr1: [],
        // dailyExpenseApprovalsMsg: '',
      };
    },
    setDailyExpenseApprovalsError: prevState => {
      return {
        ...prevState,
        dailyExpenseApprovalsLoading: false,
        dailyExpenseApprovalsData: [],
        dailyExpenseApprovalsError: true,
        // dailyExpenseApprovalsMsg: '',
      };
    },

    // Exception Summary data

    setdailyExpenseExceptionSummaryData: (prevState, action) => {
      return {
        ...prevState,
        dailyExpenseExceptionSummaryData: action.payload,
      };
    },

    // Misc expense approvals data
    setMiscExpenseApprovalsLoading: prevState => {
      return {
        ...prevState,
        miscExpenseApprovalsLoading: true,
        miscExpenseApprovalsData: [],
        miscExpenseApprovalsError: false,
        // miscExpenseApprovalsMsg: '',
      };
    },
    setMiscExpenseApprovalsData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        miscExpenseApprovalsLoading: false,
        miscExpenseApprovalsData: payload,
        miscExpenseApprovalsError: false,
        miscs: updatedMiscData(payload),
        dailyEditedRowColorIndex: [],
        // miscExpenseApprovalsMsg: '',
      };
    },
    setMiscExpenseApprovalsError: prevState => {
      return {
        ...prevState,
        miscExpenseApprovalsLoading: false,
        miscExpenseApprovalsData: [],
        miscExpenseApprovalsError: true,
        // miscExpenseApprovalsMsg: '',
      };
    },

    // approve expense modal
    setOpenApproveExpenseModal: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        isAprroveExpenseModalOpen: payload,
      };
    },
    setMiscExpenseApprovalsRequest: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        miscExpenseApprovalsRequest: payload,
      };
    },

    setApproveExpenseModalCheckbox: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        approveExpenseModalCheckbox: payload,
      };
    },
    setConfirmExpenseApprovalsLoading: prevState => {
      return {
        ...prevState,
        confirmExpenseApprovalsLoading: true,
        confirmExpenseApprovalsSucess: false,
        confirmExpenseApprovalsError: false,
        // confirmExpenseApprovalsMsg: '',
      };
    },
    setConfirmExpenseApprovalsSuccess: prevState => {
      return {
        ...prevState,
        confirmExpenseApprovalsLoading: false,
        confirmExpenseApprovalsSucess: true,
        confirmExpenseApprovalsError: false,
        dailyEditedRowColorIndex: [],
        dailyEditedRowColorIndexArr1: [],
        // confirmExpenseApprovalsMsg: '',
      };
    },
    setConfirmExpenseApprovalsError: prevState => {
      return {
        ...prevState,
        confirmExpenseApprovalsLoading: false,
        confirmExpenseApprovalsSucess: false,
        confirmExpenseApprovalsError: true,
        // confirmExpenseApprovalsMsg: '',
      };
    },
    setConfirmExpenseApprovalsReset: prevState => {
      return {
        ...prevState,
        confirmExpenseApprovalsLoading: false,
        confirmExpenseApprovalsSucess: false,
        confirmExpenseApprovalsError: false,
        // confirmExpenseApprovalsMsg: '',
      };
    },

    // reject expense modal
    setOpenRejectExpenseModal: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        isRejectExpenseModalOpen: payload,
      };
    },
    setRejectExpenseModalCheckbox: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        rejectExpenseModalCheckbox: payload,
      };
    },
    setRejectExpenseApprovalsLoading: prevState => {
      return {
        ...prevState,
        rejectExpenseApprovalsLoading: true,
        rejectExpenseApprovalsSucess: false,
        rejectExpenseApprovalsError: false,
        // rejectExpenseApprovalsMsg: '',
      };
    },
    setRejectExpenseApprovalsSuccess: prevState => {
      return {
        ...prevState,
        rejectExpenseApprovalsLoading: false,
        rejectExpenseApprovalsSucess: true,
        rejectExpenseApprovalsError: false,
        // rejectExpenseApprovalsMsg: '',
      };
    },
    setRejectExpenseApprovalsError: prevState => {
      return {
        ...prevState,
        rejectExpenseApprovalsLoading: false,
        rejectExpenseApprovalsSucess: false,
        rejectExpenseApprovalsError: true,
        // rejectExpenseApprovalsMsg: '',
      };
    },
    setRejectExpenseApprovalsReset: prevState => {
      return {
        ...prevState,
        rejectExpenseApprovalsLoading: false,
        rejectExpenseApprovalsSucess: false,
        rejectExpenseApprovalsError: false,
        // rejectExpenseApprovalsMsg: '',
      };
    },
    //  Dcr report modal
    setDcrReportPopupVisible: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        setDcrReportPopupVisible: payload,
      };
    },
    //setDeductTheAmountPopup  modal
    setDeductTheAmountPopup: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        setDeductTheAmountPopup: payload,
      };
    },
    //Da amount to deduct
    setDaAmountToDeduct: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        daAmountToDeduct: payload,
      };
    },
    //Da amount to decduct functionality
    setDaAmountCalculations: (prevState, action) => {
      const {payload} = action;

      return {
        ...prevState,
        dailyExpenseApprovalsLoading: false,
        dailyExpenseApprovalsData: payload,
        dailyExpenseApprovalsError: false,
        dailys: updatedDailyData(payload),
        dailyExpenseHQTypeAdmin: '',
      };
    },
    // setViewBillPopupVisible
    setViewBillPopupVisible: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        setViewBillPopupVisible: payload,
      };
    },
    // setErrorPopupVisible
    setErrorPopupVisible: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        setErrorPopupVisible: payload,
      };
    },
    // View Bill on Daily expense
    setexpenseApprovalsViewBillLoading: prevState => {
      return {
        ...prevState,
        expenseApprovalsViewBillLoading: true,
        expenseApprovalsViewBillData: [],
        expenseApprovalsViewBillError: false,
        // expenseApprovalsViewBillMsg: '',
      };
    },
    setexpenseApprovalsViewBillData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        expenseApprovalsViewBillLoading: false,
        expenseApprovalsViewBillData: payload,
        expenseApprovalsViewBillError: false,
        // expenseApprovalsViewBillMsg: '',
      };
    },
    setexpenseApprovalsViewBillError: prevState => {
      return {
        ...prevState,
        expenseApprovalsViewBillLoading: false,
        expenseApprovalsViewBillData: [],
        expenseApprovalsViewBillError: true,
        // expenseApprovalsViewBillMsg: '',
      };
    },
    // View Bill on Misc expense
    setMiscexpenseApprovalsViewBillLoading: prevState => {
      return {
        ...prevState,
        expenseApprovalsViewBillLoading: true,
        expenseApprovalsViewBillData: [],
        expenseApprovalsViewBillError: false,
        // miscexpenseApprovalsViewBillMsg: '',
      };
    },
    setMiscexpenseApprovalsViewBillData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        expenseApprovalsViewBillLoading: false,
        expenseApprovalsViewBillData: payload,
        expenseApprovalsViewBillError: false,
        // miscexpenseApprovalsViewBillMsg: '',
      };
    },
    setMiscexpenseApprovalsViewBillError: prevState => {
      return {
        ...prevState,
        expenseApprovalsViewBillLoading: false,
        expenseApprovalsViewBillData: [],
        expenseApprovalsViewBillError: true,
        // miscexpenseApprovalsViewBillMsg: '',
      };
    },

    // dcrReport data on Daily day click
    setDailyExpenseDcrReportLoading: prevState => {
      return {
        ...prevState,
        dailyExpenseDcrReportLoading: true,
        dailyExpenseDcrReportData: [],
        dailyExpenseDcrReportError: false,
        // dailyExpenseDcrReportMsg: '',
      };
    },
    setDailyExpenseDcrReportData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        dailyExpenseDcrReportLoading: false,
        dailyExpenseDcrReportData: payload,
        dailyExpenseDcrReportError: false,
        // dailyExpenseDcrReportMsg: '',
      };
    },
    setDailyExpenseDcrReportError: prevState => {
      return {
        ...prevState,
        dailyExpenseDcrReportLoading: false,
        dailyExpenseDcrReportData: [],
        dailyExpenseDcrReportError: true,
        // dailyExpenseDcrReportMsg: '',
      };
    },
    setDailyExpenseDcrMapUrl: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        dailyExpenseDcrMapUrl: payload,
      };
    },
    setDailyExpenseHQTypeAdmin: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        dailyExpenseHQTypeAdmin: payload,
      };
    },
    setDailyEditedRowColorIndex: (prevState, action) => {
      const {payload} = action;

      return {
        ...prevState,
        dailyEditedRowColorIndex: payload,
      };
    },
    setDailyEditedRowColorIndexArr1: (prevState, action) => {
      const {payload} = action;

      return {
        ...prevState,
        dailyEditedRowColorIndexArr1: payload,
      };
    },
    setMiscEditedRowColorIndex: (prevState, action) => {
      const {payload} = action;

      return {
        ...prevState,
        miscEditedRowColorIndex: payload,
      };
    },
    setTaDaPopupVisible: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        taDaPopupVisible: payload,
      };
    },
    setTaGridValue: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        taGridValue: payload,
      };
    },
    setDaGridValue: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        daGridValue: payload,
      };
    },
    setDailyTaEditedRowColorIndex: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        dailyTaEditedRowColorIndex: payload,
      };
    },
    setDailyDaEditedRowColorIndex: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        dailyDaEditedRowColorIndex: payload,
      };
    },
    setPopupReadOnlyData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        popupReadOnlyData: payload,
      };
    },
    setPopuptadaData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        popuptadaData: payload,
      };
    },
    setTaGrid: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        taGrid: payload,
      };
    },
    setDaGrid: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        daGrid: payload,
      };
    },
    setTaRate: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        taRate: payload,
      };
    },
    setBillPathArr: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        billPathArr: payload,
      };
    },
    setPageNo: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        pageNo: payload,
      };
    },

    setDisableSave: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        disableSave: payload,
      };
    },
    setDisableDaSave: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        disableDaSave: payload,
      };
    },
    setApproveExpensestatus: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        approveExpensestatus: payload,
      };
    },
    setDisableaApproveExpense: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        disableaApproveExpense: payload,
      };
    },
    setApprovedStaffDetails: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        approvedStaffDetails: payload,
      };
    },
    setDeductAmount: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        deductAmount: payload,
      };
    },
    setTravelDetailPopup: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        travelDetailPopup: payload,
      };
    },
    setTravelDetails: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        travelDetails: payload,
      };
    },
    setMonthlyExpense: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        monthlyExpense: payload,
      };
    },
    setFooterDatas: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        footerDatas: payload,
      };
    },
    setDownloadAllData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        downloadAllData: payload,
      };
    },
    setInvoiceStatus: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        isInvoiceExist: payload,
      };
    },
    setSubmittedBillStatus: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        IsBillSubmitted: payload,
      };
    },
    setBillDetails: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        billDetails: payload,
      };
    },
    setIsBillDeleted: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        isBillDeleted: payload,
      };
    },
    setIsSupportingDocSubmitted: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        isSupportingDocSubmitted: payload,
      };
    },
    setIsDialyAllData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        dialyAllData: payload,
      };
    },
    setSingleUserDailyExpense: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        singleUserDailyExpense: payload,
      };
    },

    setTableMessage: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        tableMessage: payload,
      };
    },
  },
});

export const approveExpenseStateActions = approveExpenseStateHandler.actions;
export const approveExpenseStateReducer = approveExpenseStateHandler.reducer;
